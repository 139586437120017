export function setCookie(name, value, days) {
  let cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN_URL;
  let date = new Date();

  let options = {
    path: "/",
    expires: date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000),
    domain: cookieDomain,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
      encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + "=([^;]*)" // eslint-disable-line
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

