import {getCookie} from './cookies';

export function googleAnalyticsInit() {
    let scriptTrackingId = process.env.REACT_APP_COOKIE_SCRIPT_TRACKING_ID || '';
    let gtmScriptTag = document.createElement('script');
    gtmScriptTag.type = 'text/javascript';
    gtmScriptTag.setAttribute('async', 'true');
    gtmScriptTag.setAttribute(
        'src',
        'https://www.googletagmanager.com/gtag/js?id=' + scriptTrackingId
    );
    document.documentElement.firstChild.appendChild(gtmScriptTag);

    window.dataLayer = window.dataLayer || [];

    window.gtag = function gtag() {
        window.dataLayer.push(arguments);
    };

    window.gtag.bind(window);

    window.gtag('js', new Date());
    window.gtag('config', scriptTrackingId);
}

export function cookieDataExists(dataKey = "") {
    let cookieData = getCookie("user-cookie-preferences");

    //check for cookie data
    if (cookieData && cookieData !== "") {
        try {
            // add !! to convert result to boolean
            return !!JSON.parse(cookieData)[dataKey];
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    return false;
}

/*
If the dataLayer already exists do nothing
If the dataLayer does not exist set it to an empty array
events will be pushed to this empty array while tracking has not been accepted
when cookies are accepted however gtm can use this data from the array
*/
export function createDataLayer() {
    window.dataLayer = window.dataLayer || [];
}

export function addToDataLayer(eventObject) {
    try {
        createDataLayer();
        window.dataLayer.push(eventObject);
    } catch (e) {
        console.log(e);
    }
}
