import { format, parseISO } from 'date-fns';
import {Buffer} from 'buffer';

const CALLBACK_PARAM = 'callback';

export const getRegionDisplayName = (key: string) => {
  return key === 'GB' ? 'United Kingdom' : key;
};

export const formatDob = (dob: string) => {
  return dob ? format(parseISO(dob), 'dd/MM/yyyy') : '';
};

export const setCallbackInLocalStorage = () => {
  let params = (new URL(document.location.toString())).searchParams;
  let callback = params.get(CALLBACK_PARAM);
  if (typeof callback === "string") {
    localStorage.setItem(CALLBACK_PARAM, callback);
  }
}

export const getCallbackFromLocalStorage = () => {
  return localStorage.getItem("callback");
}

export const decodeCallback = (callBackValue : string | null) =>  {
  if (callBackValue === null) {
    return undefined;
  }
  try {
    let dataJson = JSON.parse(Buffer.from(callBackValue, 'base64').toString())
    return {
      'partner': dataJson.partner,
      'redirectPath': dataJson.redirectPath,
      'stepupRequired': dataJson.stepupRequired,
    };
  }
  catch(err) {
    console.error(err);
    return undefined;
  }
}