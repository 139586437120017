import {color, media, spacing} from '../styles';
import {addToDataLayer} from "./Cookie/modules/analytics";

function Footer() {
    function handlePrivacyClick() {
        addToDataLayer({
            'event': 'privacy_policy',
            'group': 'account_footer',
            'pageUrl': window.location.href
        })
    }

    function handleTermsClick() {
        addToDataLayer({
            'event': 'terms_conditions_id',
            'group': 'account_footer',
            'pageUrl': window.location.href
        })
    }

    return (
        <>
            <style jsx>{`
              .footer {
                margin-top: ${spacing.medium.desktop}px;
                min-height: 75px;
                background: ${color.grey08};
                padding-left: ${spacing.small.desktop}px;
                padding-right: ${spacing.small.desktop}px;
                border-top: 2px solid ${color.grey04};
                display: flex;
                align-items: center;
              }

              .footer__container {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100%;
              }

              .footer__links {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                order: 10;
              }

              .footer__item {
                color: ${color.grey09};
                font-weight: 500;
                font-size: 16px;
                margin: 0;
                padding: 0;
                text-decoration: none;
                margin-right: ${spacing.medium.desktop}px;
              }

              .footer__item:hover {
                color: ${color.grey09};
              }

              .footer__copy {
                margin: 0;
                padding: 0;
                color: ${color.grey10};
                font-size: 12px;
                order: 10;
              }

              .footer__country {
                color: ${color.grey09};
                font-weight: 500;
                font-size: 16px;
                margin: 0;
                padding: 0;
                order: 10;
              }

              @media ${media.tabletAndLower} {
                .footer {
                  padding-top: ${spacing.small.mobile}px;
                  padding-bottom: ${spacing.small.mobile}px;
                  padding-left: ${spacing.small.mobile}px;
                  padding-right: ${spacing.small.mobile}px;
                }

                .footer__container {
                  flex-direction: column;
                  align-items: flex-start;
                }

                .footer__links {
                  justify-content: flex-start;
                }

                .footer__copy {
                  order: 2;
                }

                .footer__country {
                  order: 1;
                }

                .footer__item, .footer__country, .footer__copy {
                  margin-bottom: 20px;
                }
              }
            `}</style>
            <div className="row footer">
                <div className="footer__container">
                    <p className="footer__copy">© Virgin 2024. All rights reserved.</p>
                    <div className="footer__links">
                        <a className="footer__item" href="https://www.virgin.com/virgin-red/privacy-policy" target="_blank"
                           rel="noopener noreferrer"
                           aria-label="Privacy Policy (external link)"
                           onClick={() => {handlePrivacyClick()}}>Privacy Policy</a>
                        <a className="footer__item" href="https://www.virgin.com/cookie-policy" target="_blank"
                           rel="noopener noreferrer"
                           aria-label="Use of Cookies (external link)">Use of Cookies</a>
                        <a className="footer__item" href="https://www.virgin.com/terms-and-conditions" target="_blank"
                           rel="noopener noreferrer"
                           aria-label="Virgin ID Terms and Conditions (external link)"
                           onClick={() => {handleTermsClick()}}>
                            Virgin ID Terms and Conditions
                        </a>
                        <a className="footer__item" href="https://membersupport.red.virgin.com/hc/en-gb" target="_blank" rel="noopener noreferrer"
                           aria-label="Customer support (external link)">Customer support</a>
                    </div>
                    <p className="footer__country">United Kingdom</p>
                </div>

            </div>
        </>
    )
}

export default Footer;