import React, {useEffect} from "react";
import {loadDavinciWidget} from "../utils/loadDavinciWidget";
import Footer from "../components/Footer";
import {LoadSpinner} from "../components/LoadSpinner";
import CookieBanner from "../components/Cookie/CookieBanner";
import {getCallbackFromLocalStorage} from "../utils/helperFunctions";
import {useAuthContext} from "../auth/AuthProvider";

function Welcome() {
    const authContext = useAuthContext();
    useEffect(() => {
        setInterval(function () {
            window.location.href = process.env.REACT_APP_ENV === 'prod' ?
                'https://cdn.account.virgin.com/account-space-signed-out-timeout.html' :
                'https://cdn.' + process.env.REACT_APP_ENV + '.account.virgin.com/account-space-signed-out-timeout.html';
        }, 3567000);

        loadDavinciWidget(
            process.env.REACT_APP_PING_POLICY_ID || '',
            process.env.REACT_APP_PING_COMPANY_ID || '',
            process.env.REACT_APP_PING_DV_API_KEY || '',
            authContext.idToken,
            getCallbackFromLocalStorage()
        );
    });

    return (
        <>
            <div className="App">
                <div className="account-space__content">
                    <div id="widget" className="dvWidget">
                        <LoadSpinner/>
                    </div>
                </div>
                <Footer/>
                <CookieBanner/>
            </div>
        </>
    );
}

export default Welcome;
