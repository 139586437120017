import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "./auth/AuthProvider";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <AuthProvider>
        <link href={process.env.REACT_APP_CDN_URL + '/css/app.css?t=' + Date.now()} rel="stylesheet" type="text/css"/>
        <link href={process.env.REACT_APP_CDN_URL + '/css/ping_design_system.css?t=' + Date.now()} rel="stylesheet" type="text/css"/>
        <App/>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
