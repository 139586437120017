import { datadogRum } from '@datadog/browser-rum'

export const initDatadog = () => {
    if (!process.env.REACT_APP_DATADOG_APPLICATION_ID || !process.env.REACT_APP_DATADOG_CLIENT_TOKEN || !process.env.REACT_APP_DATADOG_SERVICE_NAME) {
        throw new Error('Missing required datadog env variables')
    }

    datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
        site: 'datadoghq.eu',
        service: process.env.REACT_APP_DATADOG_SERVICE_NAME || '',
        env: process.env.REACT_APP_ENV,
        sessionSampleRate: 80,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask'
    })

    // allows us to have screen recordings of users interactions
    datadogRum.startSessionReplayRecording()
}