import * as oauth from 'oauth4webapi';

if (!process.env.REACT_APP_PING_CLIENT_ID?.length) {
  throw new Error('Environment variable missing: REACT_APP_PING_CLIENT_ID');
}
if (!process.env.REACT_APP_PING_DOMAIN?.length) {
  throw new Error('Environment variable missing: REACT_APP_PING_DOMAIN');
}
if (!process.env.REACT_APP_PING_ACR_VALUE?.length) {
  throw new Error('Environment variable missing: REACT_APP_PING_ACR_VALUE');
}
const OIDC_CONFIG = {
  issuer: process.env.REACT_APP_PING_DOMAIN,
  clientId: process.env.REACT_APP_PING_CLIENT_ID,
  redirectUri: `${window.location.origin}`,
  acrValues: process.env.REACT_APP_PING_ACR_VALUE
};
const STORAGE_KEYS = {
  codeVerifier: 'pkce_code_verifier',
  authState: 'auth_state',
  authNonce: 'auth_nonce'
};
const OAUTH_CLIENT: oauth.Client = {
  client_id: OIDC_CONFIG.clientId,
  token_endpoint_auth_method: 'none'
};

let authServerOpenIdConfig: oauth.AuthorizationServer;

export const authInit = async () => {
  const issuer = new URL(OIDC_CONFIG.issuer);
  authServerOpenIdConfig = await oauth
    .discoveryRequest(issuer)
    .then((response) => oauth.processDiscoveryResponse(issuer, response));
};

export const redirectToLoginWithAcr = async (state?: string) => {
  return redirectToLogin(OIDC_CONFIG.acrValues, state);
};

export const redirectToLogin = async (acrValues?: string, state?: string) => {
  if (!authServerOpenIdConfig) {
    throw new Error(
      'Authentication server open-id configuration has not been retrieved. Invoke the init() function prior to redirecting the user to login.'
    );
  }

  const code_verifier = oauth.generateRandomCodeVerifier();
  sessionStorage.setItem(STORAGE_KEYS.codeVerifier, code_verifier);
  const code_challenge = await oauth.calculatePKCECodeChallenge(code_verifier);

  if (!authServerOpenIdConfig.authorization_endpoint?.length) {
    throw new Error(
      'The authorization server open-id configuration is missing a value for the authorization_endpoint field.'
    );
  }
  const nonce = oauth.generateRandomNonce();
  const stateToSendToAuthServer = state?.length ? state : oauth.generateRandomState();
  sessionStorage.setItem(STORAGE_KEYS.authState, stateToSendToAuthServer);
  sessionStorage.setItem(STORAGE_KEYS.authNonce, nonce);
  const authorizationEndpointUrl = new URL(authServerOpenIdConfig.authorization_endpoint);
  authorizationEndpointUrl.searchParams.set('client_id', OAUTH_CLIENT.client_id);
  authorizationEndpointUrl.searchParams.set('code_challenge', code_challenge);
  authorizationEndpointUrl.searchParams.set('code_challenge_method', 'S256');
  authorizationEndpointUrl.searchParams.set('redirect_uri', OIDC_CONFIG.redirectUri);
  authorizationEndpointUrl.searchParams.set('response_type', 'code');
  authorizationEndpointUrl.searchParams.set('scope', 'openid profile user:mfa');
  authorizationEndpointUrl.searchParams.set('response_mode', 'query');
  authorizationEndpointUrl.searchParams.set('state', stateToSendToAuthServer);
  // OIDC params
  if (acrValues !== undefined) {
    authorizationEndpointUrl.searchParams.set('acr_values', acrValues);
  }
  authorizationEndpointUrl.searchParams.set('nonce', nonce);

  window.location.replace(authorizationEndpointUrl);
};

export const validateAuthorizationResponse = (
  currentUrlParams: URLSearchParams
): URLSearchParams | oauth.OAuth2Error => {
  const stateFromSession = sessionStorage.getItem(STORAGE_KEYS.authState);
  if (!stateFromSession?.length) {
    throw new Error(
      'State not present in session storage. Cannot perform authorization response validation'
    );
  }
  return oauth.validateAuthResponse(
    authServerOpenIdConfig,
    OAUTH_CLIENT,
    currentUrlParams,
    stateFromSession
  );
};

export const performTokenExchange = async (callbackParams: URLSearchParams) => {
  try {
    const codeVerifier = sessionStorage.getItem(STORAGE_KEYS.codeVerifier);
    if (!codeVerifier) {
      throw new Error('code_verifier not present in sessionStorage. Cannot perform token exchange');
    }
    const tokenResponse = await oauth.authorizationCodeGrantRequest(
      authServerOpenIdConfig,
      OAUTH_CLIENT,
      callbackParams,
      OIDC_CONFIG.redirectUri,
      codeVerifier
    );
    const expectedNonce = sessionStorage.getItem(STORAGE_KEYS.authNonce);
    if (!expectedNonce?.length) {
      throw new Error('nonce not present in session storage');
    }
    const tokenResponseProcessingResult = await oauth.processAuthorizationCodeOpenIDResponse(
      authServerOpenIdConfig,
      OAUTH_CLIENT,
      tokenResponse,
      expectedNonce
    );
    if (oauth.isOAuth2Error(tokenResponseProcessingResult)) {
      console.error(tokenResponseProcessingResult);
      throw new Error('token response error');
    }

    const openIdTokenResponse: oauth.OpenIDTokenEndpointResponse = tokenResponseProcessingResult;
    // perform id token signature verification
    const responseSearchParams = new URLSearchParams();
    responseSearchParams.set('response', openIdTokenResponse.id_token);
    responseSearchParams.set('iss', authServerOpenIdConfig.issuer);
    await oauth.validateJwtAuthResponse(
      authServerOpenIdConfig,
      OAUTH_CLIENT,
      responseSearchParams,
      oauth.skipStateCheck
    );

    return openIdTokenResponse;
  } finally {
    clearStorage();
  }
};

export const userHasSteppedUp = (
  openIdTokenResponse: oauth.OpenIDTokenEndpointResponse
): boolean => {
  const idTokenClaims = oauth.getValidatedIdTokenClaims(openIdTokenResponse);
  return (
    idTokenClaims['amr'] !== undefined &&
    (idTokenClaims['amr'] as string[]).length > 0 &&
    amrClaimHasTwoValidAuthFactors(idTokenClaims['amr'] as string[])
  );
};

const amrClaimHasTwoValidAuthFactors = (amr: string[]) => {
  const possibleCombinations: string[][] = [
    ['bio', 'biosu'],
    ['bio', 'otp'],
    ['bio', 'sms'],
    ['sms', 'otp'],
    ['otp', 'sms'],
    ['otp', 'bio'],
    ['sms', 'bio'],
    ['biosu', 'bio']
  ];
  const amrJoined = amr.join();
  return possibleCombinations.some((combination) => combination.join() === amrJoined);
};

const clearStorage = () => {
  sessionStorage.removeItem(STORAGE_KEYS.authState);
  sessionStorage.removeItem(STORAGE_KEYS.authNonce);
  sessionStorage.removeItem(STORAGE_KEYS.codeVerifier);
};
