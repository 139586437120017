import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Root from './pages/Root';
import {PATHS} from './route/paths';
import {initDatadog} from "./utils/datadog";
import {cookieDataExists} from "./components/Cookie/modules/analytics";

function App() {
    useEffect(() => {
        const interval = setInterval(() => {
            if (cookieDataExists('analytics-cookies')) {
                clearInterval(interval)
                // enable on strictly necessary cookie acceptance
                initDatadog()
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <Router>
            <Routes>
                <Route path={PATHS.ROOT} element={<Root/>}/>
            </Routes>
        </Router>
    );
}

export default App;
