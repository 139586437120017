export const loadDavinciWidget = (policyId: string, companyId: string, dvApiKey: string, access_token: string | undefined, callback: string | null) => {
  //*** Build the get DaVinci SDK Token URL. ***/
  const dvGetTokenUrl =
      "https://orchestrate-api.pingone.eu/v1/company/" +
      companyId + "/sdktoken";

  //*** Add the DaVinci API Key from your DaVinci application. ***/
  let headers = new Headers();
  headers.append("X-SK-API-KEY", dvApiKey);

  let requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow" as RequestRedirect,
  };

  //*** Retrieve DaVinci SDK Token ***/
  fetch(dvGetTokenUrl,
      requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        let props = {
          config: {
            method: "runFlow",
            apiRoot: "https://auth.pingone.eu/",
            accessToken: responseData.access_token,
            companyId: companyId,
            policyId: policyId,
            parameters: {
              "userToken": access_token,
              "callback": (callback ? callback : "")
            }
          },
          useModal: false
        };
        /*** Invoke the Widget ****/
        /*global davinci*/
        // @ts-ignore
        davinci.skRenderScreen(
            document.getElementsByClassName("dvWidget")[0],
            props
        );
      })
      .catch((error) => console.error("error", error));
};