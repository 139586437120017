import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import {
  validateAuthorizationResponse,
  authInit,
  performTokenExchange,
  redirectToLogin,
  userHasSteppedUp,
  redirectToLoginWithAcr
} from '../utils/auth';
import { OAuth2Error, OpenIDTokenEndpointResponse } from 'oauth4webapi';
import {
  decodeCallback,
  getCallbackFromLocalStorage,
  setCallbackInLocalStorage
} from '../utils/helperFunctions';

interface AuthProviderProps {
  children: ReactNode;
}

export interface AuthContextProps {
  error: string | null;
  idToken?: string;
  isAuthenticated: boolean;
}

export const useAuthContext = (): AuthContextProps => {
  return useContext(AuthContext);
};

const AuthContext = createContext<AuthContextProps>({
  error: null,
  isAuthenticated: false
});

const isStepUpRequired = () => {
  const callbackQueryStringParamValue = getCallbackFromLocalStorage();
  if (callbackQueryStringParamValue !== null) {
    const decodedCallback = decodeCallback(callbackQueryStringParamValue);
    if (decodedCallback?.stepupRequired !== undefined) {
      return decodedCallback.stepupRequired as boolean;
    }
  }
  return true;
};

const isCallbackFromAuthServer = (searchParams: URLSearchParams) => {
  return (
    (searchParams.get('error')?.length || searchParams.get('code')?.length) &&
    searchParams.get('state')?.length
  );
};

const isNoSessionError = (errorResponse: OAuth2Error) => {
  return errorResponse && errorResponse.error_description === 'no-session';
};

const clearQueryStringParams = () => {
  window.history.replaceState(null, '', window.location.pathname);
};

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [idToken, setIdToken] = useState<string | undefined>();
  const [error, setError] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const handleError = (err: any) => {
    if (err) {
      console.error(err);
      setError(err instanceof Error ? err.message : String(err));
    }
  };

  const redirectToSignInWithoutAcr = () => {
    redirectToLogin().catch(handleError);
  };

  const redirectToSignInWithAcr = () => {
    redirectToLoginWithAcr().catch(handleError);
  };

  const redirectToSignIn = () => {
    if (isStepUpRequired()) {
      redirectToSignInWithAcr();
    } else {
      redirectToSignInWithoutAcr();
    }
  };

  useEffect(() => {
    authInit()
      .then(() => {
        let searchParams = new URLSearchParams(window.location.search);
        if (isCallbackFromAuthServer(searchParams)) {
          // The user has been redirected from the auth-server
          const authorizationResponseParams = validateAuthorizationResponse(searchParams);
          const errorResponse = authorizationResponseParams as OAuth2Error;
          if (isNoSessionError(errorResponse)) {
            redirectToSignInWithoutAcr();
            return;
          } else if (authorizationResponseParams instanceof URLSearchParams) {
            performTokenExchange(authorizationResponseParams)
              .then((tokenResponse: OpenIDTokenEndpointResponse) => {
                setIdToken(tokenResponse.id_token);
                if (isStepUpRequired() && !userHasSteppedUp(tokenResponse)) {
                  redirectToSignInWithAcr();
                  return;
                }
                setIsAuthenticated(tokenResponse.id_token?.length > 0);
                clearQueryStringParams();
              })
              .catch(handleError);
            return;
          }
          throw new Error(
            `Unexpected authorization response received from the server: ${JSON.stringify(
              authorizationResponseParams
            )}`
          );
        } else if (!isAuthenticated) {
          setCallbackInLocalStorage();
          redirectToSignIn();
        }
      })
      .catch(handleError);
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps
  // the above eslint warning has been disabled because the effect doesn't use the reactive values it simply sets them
  // the empty dependency array is required because useEffect should only run once after the initial render

  return (
    <AuthContext.Provider value={{ idToken, error, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
