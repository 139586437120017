import '../App.css';
import {LoadSpinner} from '../components/LoadSpinner';
import Welcome from "./Welcome";
import {useAuthContext} from "../auth/AuthProvider";

export default Root;

function Root() {
    const authContext = useAuthContext();
    const {isAuthenticated} = authContext;

    if (isAuthenticated) {
        return <Welcome />;
    }

    return <LoadSpinner/>;
}
