import {useEffect, useState} from "react";
import {addToDataLayer, cookieDataExists, googleAnalyticsInit} from "./modules/analytics";
import {setCookie} from "./modules/cookies";

function CookieBanner() {
    let [cookieBannerIsVisible,setCookieBannerIsVisible] = useState(true);
    let [cookieModalIsVisible,setCookieModalIsVisible] = useState(false);
    let [cookieProperties, setCookieProperties] = useState({
        'marketing-advertising-cookies': false,
        'functional-cookies': false,
        'analytics-cookies': false,
        'necessary-cookies': true
    });

    useEffect(() => {
        let showCookieBanner = !(cookieDataExists('necessary-cookies') || cookieDataExists('analytics-cookies'))
        setCookieBannerIsVisible(showCookieBanner);


        if(cookieDataExists('analytics-cookies')) {
            googleAnalyticsInit();
        }
    },[])

    function saveCurrentCookieData(dataToUpdate :any) {
        let data = JSON.stringify({...dataToUpdate});
        setCookie('user-cookie-preferences', data, 365 * 5);
    }

    function cookieAcceptAllHandler() {
        let newProperties = {
            ...cookieProperties,
            'marketing-advertising-cookies': true,
            'functional-cookies': true,
            'analytics-cookies': true,
            'necessary-cookies': true
        }

        setCookieProperties(newProperties);
        saveCurrentCookieData(newProperties);

        addToDataLayer({
            'event': 'accept_all_cookies',
            'group': 'cookies',
            'pageUrl': window.location.href
        });

        googleAnalyticsInit();

        setCookieBannerIsVisible(false);
        setCookieModalIsVisible(false);
    }

    function cookieChooseHandler() {
        addToDataLayer({
            'event': 'configure_cookies',
            'group': 'cookies',
            'pageUrl': window.location.href
        });

        setCookieModalIsVisible(true);
    }

    function modalCloseHandler() {
        setCookieModalIsVisible(false);
    }

    function modalSaveHandler() {
        if(cookieProperties['analytics-cookies']) {
            googleAnalyticsInit();
        }

        saveCurrentCookieData(cookieProperties);

        addToDataLayer({
            'event': 'save_configured_cookies',
            'group': 'cookies',
            'pageUrl': window.location.href
        });

        setCookieBannerIsVisible(false);
        setCookieModalIsVisible(false);
    }

    function modalNecessaryToggleHandler() {
        setCookieProperties({
            ...cookieProperties,
            'necessary-cookies': true
        })
    }

    function modalAnalyticsToggleHandler() {
        setCookieProperties({
            ...cookieProperties,
            'analytics-cookies': !cookieProperties['analytics-cookies']
        })
    }

    function modalFunctionalToggleHandler() {
        setCookieProperties({
            ...cookieProperties,
            'functional-cookies': !cookieProperties['functional-cookies']
        })
    }

    function modalMarketingAdvertisingToggleHandler() {
        setCookieProperties({
            ...cookieProperties,
            'marketing-advertising-cookies': !cookieProperties['marketing-advertising-cookies']
        })
    }

    function cookiePolicyHandler() {
        addToDataLayer({
            'event': 'cookies_read_cookies',
            'group': 'cookies',
            'pageUrl': window.location.href
        });
    }

    function privacyPolicyHandler() {
        addToDataLayer({
            'event': 'cookies_read_privacy',
            'group': 'cookies',
            'pageUrl': window.location.href
        });
    }

    return (
        <>
            <div className={cookieBannerIsVisible ? 'cookie-banner cookie-banner--active' : 'cookie-banner'}>
                <div className="cookie-banner__inner">
                    <div className="cookie-banner__layout col col-sm-8">
                        <h2 className="cookie-banner__title">We use cookies to improve your experience with Virgin
                            ID</h2>
                        <p className="cookie-banner__description">
                            You can read our full <a className="cookie-banner__link cookie-banner__privacy-policy"
                                                     href="/" onClick={privacyPolicyHandler}>Privacy Policy</a> and
                            <a className="cookie-banner__link cookie-banner__cookie-policy" href="https://www.virgin.com/virgin-red/privacy-policy" onClick={cookiePolicyHandler}> Cookie
                                Policy</a> which explains how we use cookies.
                        </p>
                        <div className="cookie-banner__button-container">
                            <button className="cookie-banner__button cookie-banner__accept" onClick={cookieAcceptAllHandler}>Accept all</button>
                            <button
                                className="cookie-banner__button cookie-banner__choose cookie-banner__button--white" onClick={cookieChooseHandler}>Let
                                me choose
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cookieModalIsVisible ? 'cookie-banner-modal cookie-banner-modal--active' : 'cookie-banner-modal'}>
                <div className="cookie-banner-modal__inner">
                    <button className="cookie-banner-modal__close" onClick={modalCloseHandler}></button>
                    <h3 className="cookie-banner-modal__title">Cookie preferences</h3>
                    <p className="cookie-banner-modal__description">
                        We use cookies (yum!) and other technology to track how you interact with us, our partners and
                        to understand you. They enable us to personalise your experience and provide you with more
                        relevant content based on things you’ve shown an interest in or we think you might like. They
                        also help us keep this site running smoothly. You can change these settings at any time on the
                        <a className="cookie-banner-modal__link" href="/" onClick={cookiePolicyHandler}> cookie policy page</a>.
                    </p>
                    <div className="cookie-banner-modal__item-container">
                        <div className="cookie-banner-modal__item">
                            <div className="cookie-banner-modal__toggle-container">
                                <p className="cookie-banner-modal__item-title">Strictly necessary cookies</p>
                                <label className="cookie-banner-modal__toggle">
                                    <input className="cookie-banner-modal__toggle-input cookie-banner-modal__necessary"
                                           type="checkbox" checked={cookieProperties['necessary-cookies']} onChange={modalNecessaryToggleHandler}/>
                                    <span className="cookie-banner-modal__toggle-slider"></span>
                                </label>
                            </div>
                            <p className="cookie-banner-modal__item-description">
                                We need to use cookies to enable core site functionality (like saving these preferences)
                                -
                                sorry you can't switch this one off
                            </p>
                        </div>
                        <div className="cookie-banner-modal__item">
                            <div className="cookie-banner-modal__toggle-container">
                                <p className="cookie-banner-modal__item-title">Marketing & Advertising</p>
                                <label className="cookie-banner-modal__toggle">
                                    <input
                                        className="cookie-banner-modal__toggle-input cookie-banner-modal__marketing-advertising"
                                        type="checkbox" checked={cookieProperties['marketing-advertising-cookies']} onChange={modalMarketingAdvertisingToggleHandler}/>
                                    <span className="cookie-banner-modal__toggle-slider"></span>
                                </label>
                            </div>
                            <p className="cookie-banner-modal__item-description">
                                This allows us to show you advertising campaigns we think you'll like, hide others we
                                think you won't and measure their effectiveness.
                            </p>
                        </div>
                        <div className="cookie-banner-modal__item">
                            <div className="cookie-banner-modal__toggle-container">
                                <p className="cookie-banner-modal__item-title">Useful features</p>
                                <label className="cookie-banner-modal__toggle">
                                    <input className="cookie-banner-modal__toggle-input cookie-banner-modal__functional"
                                           type="checkbox" checked={cookieProperties['functional-cookies']} onChange={modalFunctionalToggleHandler}/>
                                    <span className="cookie-banner-modal__toggle-slider"></span>
                                </label>
                            </div>
                            <p className="cookie-banner-modal__item-description">
                                This allows you to use some useful features on our site, like the option to 'Remember
                                me'.
                            </p>
                        </div>
                        <div className="cookie-banner-modal__item">
                            <div className="cookie-banner-modal__toggle-container">
                                <p className="cookie-banner-modal__item-title">Visit tracking</p>
                                <label className="cookie-banner-modal__toggle">
                                    <input className="cookie-banner-modal__toggle-input cookie-banner-modal__analytics"
                                           type="checkbox" checked={cookieProperties['analytics-cookies']} onChange={modalAnalyticsToggleHandler}/>
                                    <span className="cookie-banner-modal__toggle-slider"></span>
                                </label>
                            </div>
                            <p className="cookie-banner-modal__item-description">
                                We'll give you a personalised experience of our website across multiple visits. This
                                also
                                helps us understand whether you like our website enough to return.
                            </p>
                        </div>
                    </div>
                    <div className="cookie-banner-modal__button-container">
                        <button className="cookie-banner-modal__button cookie-banner-modal__save" onClick={modalSaveHandler}>Save and close
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CookieBanner;